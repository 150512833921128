import React, {
  Suspense,
} from 'react';
import {
  // BrowserRouter as Router,
  // Link,
  // Switch,
  Navigate,
  // Route,
} from 'react-router-dom';
import { getUsername } from '../utils/auth';

export const lazyComponent = (
  comp, auth = true, fallback = <div>loading...</div>) => {
    return props => {
      // const a = getUsername();
      if (auth && !getUsername() ) {
        return <Navigate to={`/login-page`}/>;
      }
      const Component = React.lazy(comp);
      return (
        <Suspense fallback={fallback}>
          <Component {...props}/>
        </Suspense>
      );
    };
  };
