import { getAuth } from '../utils/auth';
import qs from 'qs';

const fillURLParams = (url, params) => {
  let paramMap = {...params};
  return url.replace(/\/:(\w+)/g, (ss, match) => {
    return paramMap[match] ? '/' + paramMap[match] : ss;
  });
};

const fillURLQuery = (url, params) => {
  const q = qs.stringify(params);
  return `${url}?${q}`;
};

export const handleResp = (resp) => {
  if (!resp.ok) {
    switch (resp.status) {
    case 401:
      // message.error("401 未登录或非法 token ，请重新登录", 10);
      break;
    case 403:
      // message.error("403 没有权限", 10);
      break;
    default:
    }
    throw resp;
  }
  return resp.json();
};

// export const req = (url, options) => {
//   return window.fetch(url, options)
//     .then(handleResp).then(resp => {
//       console.log(resp);
//       return {total: resp.count || resp.total || 0, data: resp.data};
//     });
// };

export const buildAPI = (method) => {

  return (url, params) => {

    const noBody = method === 'GET' || method === 'DELETE';

    if (/:/.test(url)) {
      url = fillURLParams(url, params);
    }
    let data = null;
    if (noBody) {
      url = fillURLQuery(url, params);
    } else {
      data = JSON.stringify(params);
    }
    const token = getAuth();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
    const options = {
      headers: headers,
      method: method,
      body: data,
      credentials: 'same-origin',
    };

    return window.fetch(url, options).then(handleResp).then(resp => {
      return resp;
    });
  };
};

export const get = buildAPI('GET');
export const post = buildAPI('POST');
export const put = buildAPI('PUT');
export const del = buildAPI('DELETE');
