import React from 'react';
import {
  BrowserRouter as Router,
  // Link,
  Routes,
  // Redirect,
  Route,
} from 'react-router-dom';
import { lazyComponent } from './utils/component';
import LayoutDefault from './layouts/default';
// import Redirect from './utils/redirect';

const Home = lazyComponent(() => import('./pages/home'));
const ACL = lazyComponent(() => import('./pages/acl'));
const Unblock = lazyComponent(() => import('./pages/unblock'));
const Build = lazyComponent(() => import('./pages/build'));
const Login = lazyComponent(() => import('./pages/login'), false);
const Logout = lazyComponent(() => import('./pages/logout'), false);
const LoginCallback = lazyComponent(() => import('./pages/login-callback'), false);

const R = () => (
  <Router basename={process.env.REACT_APP_PREFIX}>
    <Routes>
      <Route element={<LayoutDefault/>}>
        <Route path={`/`} element={<Home/>}/>
        <Route path={`/acl`} element={<ACL/>}/>
        <Route path={`/build-list`} element={<Build/>}/>
        <Route path={`/unblock`} element={<Unblock/>}/>
        <Route path={`/login-page`} element={<Login/>}/>
        <Route path={`/logout-page`} element={<Logout/>}/>
        <Route path={`/login-callback`} element={<LoginCallback/>}/>
      </Route>
    </Routes>
  </Router>
);

export default R;
