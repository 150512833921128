const AUTH_KEY = "DEVOPS_LOGIN";
const ADMIN_KEY = "DEVOPS_ADMIN";
const ADMIN_FLAG_KEY = "DEVOPS_ADMIN_FLAG";
const USERNAME_KEY = "DEVOPS_USERNAME";
const NAME_KEY = "DEVOPS_NAME";

export const getAuth = () => {
  return window.localStorage.getItem(AUTH_KEY);
};

export const setAuth = (token) => {
  return window.localStorage.setItem(AUTH_KEY, token);
};

export const removeAuth = () => {
  return window.localStorage.removeItem(AUTH_KEY);
};

export const getAdmin = () => {
  return window.localStorage.getItem(ADMIN_KEY);
};

export const setAdmin = () => {
  return window.localStorage.setItem(ADMIN_KEY, true);
};

export const removeAdmin = () => {
  return window.localStorage.removeItem(ADMIN_KEY);
};

export const getUsername = () => {
  return window.localStorage.getItem(USERNAME_KEY);
};

export const setUsername = (username) => {
  return window.localStorage.setItem(USERNAME_KEY, username);
};

export const removeUsername = () => {
  return window.localStorage.removeItem(USERNAME_KEY);
};

export const getName = () => {
  return window.localStorage.getItem(NAME_KEY);
};

export const setName = (name) => {
  return window.localStorage.setItem(NAME_KEY, name);
};

export const removeName = () => {
  return window.localStorage.removeItem(NAME_KEY);
};

export const getAdminFlag = () => {
  return window.localStorage.getItem(ADMIN_FLAG_KEY);
}

export const setAdminFlag = () => {
  return window.localStorage.setItem(ADMIN_FLAG_KEY, true);
}

export const removeAdminFlag = () => {
  return window.localStorage.removeItem(ADMIN_FLAG_KEY);
}

export const clearAuth = () => {
  removeAuth();
  removeUsername();
  removeName();
  removeAdmin();
  removeAdminFlag();
};
