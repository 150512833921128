import React from 'react';
import { Link } from 'react-router-dom';
import {
  getUsername,
  getAdminFlag,
  getAdmin,
  setAdmin,
  removeAdmin,
} from '../../utils/auth';
import { toast } from 'react-hot-toast'
import api from '../../api';

const Nav = props => {


  const left = (
    <ul key="left-nav">
      <li><Link to={"/"}><strong>Marriott Unified Platform Helper</strong></Link></li>
    </ul>
  );

  const username = getUsername();

  console.log(`username: ${username}`);

  const [admin, set] = React.useState(getAdmin());
  const [menu, setMenu] = React.useState();

  const enableAdmin = ev => {
    ev.preventDefault();

    const fetch = async () => {
      const resp = await api.unblock.isAdmin();
      if (resp.data.is_admin) {
        setAdmin();
        set(true);
        toast.success("admin privilege enabled");
      } else {
        toast.error("you are not admin");
      }
    };
    fetch();
  };

  const dropAdmin = ev => {
    ev.preventDefault();
    removeAdmin();
    set(false);
    toast.success("admin privilege dropped");
  };

  React.useEffect(() => {
    if (!!getUsername()) {

      const links = [];

      if (getAdminFlag()) {

        if (getAdmin()) {
          links.push(<li><Link to={"#"} onClick={ev => {dropAdmin(ev);}}>Drop Admin Privilege</Link></li>);
        } else {
          links.push(<li><Link to={"#"} onClick={ev => {enableAdmin(ev);}}>Enable Admin</Link></li>);
        }
      }

      links.push(<li><Link to={"/logout-page"}>Logout</Link></li>);

      setMenu(
        [left,
         <ul key="right-nav">
           {links}
         </ul>
        ]);
    }
    // eslint-disable-next-line
  }, [admin]);

  return (
    <nav>{menu}</nav>
  );

};

export default Nav;
